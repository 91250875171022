<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        keyword-placeholder="活动名"
        :queryInfo.sync="queryInfo"
        :tool-list="[ 'keyword']"
        :show-clear-btn="false"
        style="display: inline-block;"
        @on-search="renderTable(1)" />
      <div class="header-button fr">
        <el-button v-permission="['app:activity:edit']" type="primary" size="small" @click="handleRouter">添加活动
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="title" label="活动标题" min-width="150" />
      <el-table-column label="活动封面" width="200" align="center">
        <template v-slot="{row}">
          <el-image
            v-if="row.previewImg"
            style="width: 100%; height: auto"
            :src="`${$fileUrl}${row.previewImg}`"
            :preview-src-list="[`${$fileUrl}${row.previewImg}`]"
            fit="contain" />
          <span class="font-grey" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号链接" width="100" align="center">
        <template v-slot="{row}">
          <el-link v-if="row.mpUrl" type="primary" :href="row.mpUrl" target="_blank">点击跳转</el-link>
          <span class="font-grey" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="报名开始时间" width="160" align="center" />
      <el-table-column prop="endTime" label="报名结束时间" width="160" align="center" />
      <el-table-column label="是否轮播" width="130" align="center">
        <template v-slot="{row}">
          <el-switch
            :disabled="!hasPermission('app:activity:edit')"
            v-model="row.topFlag"
            @change="handleSwitch($event,row)"
            active-color="var(--color-success)"
            inactive-color="#d0d6e8"
            :active-value="1"
            active-text="是"
            :inactive-value="0"
            inactive-text="否" />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="200" />
      <el-table-column label="操作" width="290" fixed="right">
        <template v-slot="{row}">
          <!--  只能编辑管辖学院的活动  -->
          <template
            v-if="!$store.state.login.userInfo.collegeId||$store.state.login.userInfo.collegeId===row.collegeId">
            <el-button v-permission="['app:activity:edit']" type="primary" size="mini" @click="handleRouter(row)">编辑
            </el-button>
            <el-button type="primary" plain size="mini" @click="showDialog(row.id,row.title)">查看报名情况</el-button>
            <el-button v-permission="['app:activity:del']" size="mini" type="danger"
                       @click="del(delActivityApi,row.id,`活动-${row.title}`)">删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!--  根据活动id 查看报名记录  -->
    <activity-join-record-dialog ref="dialogRef"
                                 :visible.sync="visibleDialog"
                                 v-if="visibleDialog"
                                 @on-close="renderTable" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { delActivityApi, pageActivityApi, saveActivityApi } from '@/api/app-manage/activity-api'
import ActivityJoinRecordDialog from '@/views/pages/appManage/activity/component/activityJoinRecordDialog.vue'
import { hasPermission } from '@/utils/common-js'

export default {
  name: 'activity',
  components: { ActivityJoinRecordDialog },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        collegeId: this.$store.state.login.userInfo.collegeId || ''
      }
    }
  },
  created () {
    this.renderTable(1)
  },
  methods: {
    hasPermission,
    delActivityApi, // 删除api
    // 获取新闻列表
    renderTable (pageNum) {
      this.getTableData(pageActivityApi, pageNum)
    },
    // 是否首页显示
    handleSwitch (val, row) {
      // 缺少封图,禁止置顶
      if (!row.previewImg) {
        this.$message.error('缺少活动封面,无法进行首页显示操作。')
        row.topFlag = row.topFlag ? 0 : 1
        return
      }
      let _type = 'error'
      let _msg = `确定要取消<b class="danger">活动-${row.title}</b>在首页显示吗?`
      if (val) {
        _type = 'success'
        _msg = `是否确定将<b class="primary">活动-${row.title}</b>设置为首页显示?`
      }
      this.$confirm(`<p class="confirm-message">${_msg}</p>`, '首页显示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: _type,
        dangerouslyUseHTMLString: true
      }).then(async () => {
        this.loading = true
        try {
          await saveActivityApi(row)
          this.$message.success('首页显示状态设置成功!')
        } catch (e) {
          if (!e) {
            this.$message.error('设置失败,请重试!')
          }
          row.topFlag = row.topFlag ? 0 : 1
        } finally {
          this.loading = false
        }
      }).catch(() => {
        row.topFlag = row.topFlag ? 0 : 1
      })
    },
    // 发布活动
    handleRouter (row) {
      if (row) {
        this.$router.push({
          path: '/appManage/activity/activityForm',
          query: { id: row.id }
        })
      } else {
        this.$router.push('/appManage/activity/activityForm')
      }
    }
  }
}
</script>
