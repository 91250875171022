<template>
  <el-dialog
    class="activity-join-record-dialog"
    :title="`${title}-报名记录`"
    v-loading="loadData.loading"
    :visible.sync="show"
    width="920px"
    @close="close">
    <table-view
      :total="total"
      :query-info.sync="queryInfo"
      @reload-table="renderTable"
    >
      <template #header>
        <view-search-form
          v-model="queryInfo.activityId"
          keyword-placeholder="报名人"
          :queryInfo.sync="queryInfo"
          :tool-list="[ 'keyword']"
          :filter-keys="['activityId']"
          :show-clear-btn="false"
          style="display:inline-block"
          @on-search="renderTable(1)" />
        <div class="header-button fr">
          <!--  todo queryInfo增加userType   -->
          <el-radio-group v-model="queryInfo.userType" size="small" @change="renderTable(1)">
            <el-radio-button :label="null">全部</el-radio-button>
            <el-radio-button :label="2">学生</el-radio-button>
            <el-radio-button :label="3">教师</el-radio-button>
          </el-radio-group>
          <el-button type="success" size="small" @click="exportStudentInfo">导出报名数据</el-button>
        </div>
      </template>
      <el-table :data="tableData" v-loading="loading" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50" />
        <el-table-column prop="userName" label="报名人" width="140" />
        <el-table-column prop="collegeName" label="学院" min-width="160" />
        <el-table-column prop="className" label="班级" min-width="240" />
        <el-table-column prop="phone" label="联系电话" width="140" />
        <el-table-column label="类型" width="80" align="center">
          <template v-slot="{row}">
            {{ row.userType === 2 ? '学生' : (row.userType === 3 ? '教师' : '') }}
          </template>
        </el-table-column>
      </el-table>
    </table-view>
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { pageActivityJoinRecordApi } from '@/api/app-manage/activity-api'

export default {
  name: 'activityJoinRecordDialog',
  mixins: [dialog, tableView],
  data () {
    return {
      queryInfo: {
        activityId: null,
        userType: null
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.queryInfo.activityId = this.id
      }
    })
  },
  methods: {
    async renderTable (pageNum) {
      if (this.queryInfo.activityId) {
        this.loadData.loading = true
        await this.getTableData(pageActivityJoinRecordApi, pageNum)
        this.loadData.loading = false
      }
    },
    handleUserType (type) {
      // this.joinList = type === 2 ? this.stuList : this.teacherList
    },
    // 导出已报名名单
    exportStudentInfo () {
      this.$http.exportExcelHttp.exportStudentActivityInfo({
        activityId: this.queryInfo.activityId
      }, '活动报名表').then(res => {
      })
    }
  }
}
</script>

<style lang="scss">
.activity-join-record-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
