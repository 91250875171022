var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{staticStyle:{"display":"inline-block"},attrs:{"keyword-placeholder":"活动名","queryInfo":_vm.queryInfo,"tool-list":[ 'keyword'],"show-clear-btn":false},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button fr"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:activity:edit']),expression:"['app:activity:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleRouter}},[_vm._v("添加活动 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"title","label":"活动标题","min-width":"150"}}),_c('el-table-column',{attrs:{"label":"活动封面","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.previewImg)?_c('el-image',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":("" + _vm.$fileUrl + (row.previewImg)),"preview-src-list":[("" + _vm.$fileUrl + (row.previewImg))],"fit":"contain"}}):_c('span',{staticClass:"font-grey"},[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"label":"公众号链接","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mpUrl)?_c('el-link',{attrs:{"type":"primary","href":row.mpUrl,"target":"_blank"}},[_vm._v("点击跳转")]):_c('span',{staticClass:"font-grey"},[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"startTime","label":"报名开始时间","width":"160","align":"center"}}),_c('el-table-column',{attrs:{"prop":"endTime","label":"报名结束时间","width":"160","align":"center"}}),_c('el-table-column',{attrs:{"label":"是否轮播","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"disabled":!_vm.hasPermission('app:activity:edit'),"active-color":"var(--color-success)","inactive-color":"#d0d6e8","active-value":1,"active-text":"是","inactive-value":0,"inactive-text":"否"},on:{"change":function($event){return _vm.handleSwitch($event,row)}},model:{value:(row.topFlag),callback:function ($$v) {_vm.$set(row, "topFlag", $$v)},expression:"row.topFlag"}})]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"200"}}),_c('el-table-column',{attrs:{"label":"操作","width":"290","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.$store.state.login.userInfo.collegeId||_vm.$store.state.login.userInfo.collegeId===row.collegeId)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:activity:edit']),expression:"['app:activity:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleRouter(row)}}},[_vm._v("编辑 ")]),_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id,row.title)}}},[_vm._v("查看报名情况")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:activity:del']),expression:"['app:activity:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.delActivityApi,row.id,("活动-" + (row.title)))}}},[_vm._v("删除 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('activity-join-record-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }